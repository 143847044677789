<template>
  <ul>
    <li
      v-for="it in limited"
      :key="it.badgeData.categoryId"
      class="trustyou-badge m-b-2x">
      <div class="text">
        {{ it.text }}
      </div>
      <div class="subtext is-size-7">
        {{ it.subtext }}
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'TrustYouBadgeList',
  props: {
    badgeList: {
      type: Array,
      default () {
        return []
      }
    },
    limit: {
      type: Number,
      default: 3
    },
    popularityThreshold: {
      type: Number,
      default: 11
    }
  },
  computed: {
    limited () {
      // Filter badgeData with categoryId and popularity between 0 < x < 11
      return this.badgeList.filter(({ badgeData } = {}) => badgeData.categoryId && badgeData.popularity < this.popularityThreshold && badgeData.popularity > 0).splice(0, this.limit)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";

  .trustyou-badge {
    padding: $bleed $bleed;
    max-width: 215px;
    background-color: $grey-lighter;
    text-align: center;

    .text {
      font-weight: $weight-bold;
      color: $primary;
    }
  }

</style>
