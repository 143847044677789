<template>
  <BadgeOutline
    v-if="tripType && !layout"
    class="popular-badge"
    :no-margin="noMargin">
    <i :class="['ty-icon', icon]" />
    <span
      class="ty-text"
      v-html="text" />
  </BadgeOutline>
  <div v-else>
    <i :class="['ty-icon', icon]" />
    <span
      class="ty-text"
      v-html="text" />
  </div>
</template>

<script>
import BadgeOutline from './BadgeOutline'

export default {
  name: 'TrustYouPopularWith',
  components: {
    [BadgeOutline.name]: BadgeOutline
  },
  props: {
    tripType: String,
    text: String,
    noMargin: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon () {
      let typ
      switch (this.tripType) {
      case 'friends':
        typ = 'group'
        break
      default:
        typ = this.tripType
        break
      }
      return `ty-icon-${typ}`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .ty-icon {
    margin-right: $bleed;
    color: $success;
    font-size: 1.2em;
  }

  .ty-icon, .ty-text {
    vertical-align: middle;
    // display: inline-block;
    // color: $dark;
  }

  .popular-badge {
    background-color: $grey-lighter;
    color: $primary;
  }
</style>
