<template>
  <p>
    <i :class="['ty-icon', icon]" />
    <span
      class="ty-text"
      v-html="output" />
  </p>
</template>

<script>
export default {
  name: 'TrustYouHotelType',
  props: {
    categoryId: String,
    shortText: String,
    text: String,
    sentiment: String,
    textType: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    icon () {
      return `ty-icon-${this.categoryId}`
    },
    output () {
      return this[this.textType]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .ty-icon {
    margin-right: $bleed;
  }
  .ty-icon, .ty-text {
    // vertical-align: middle;
    // display: inline-block;
    // color: $dark;
  }
  p {
    white-space: normal;
    &:not(:last-child) {
      margin-bottom: $bleed;
    }
  }
  p ::v-deep pos {
    color: $pos-color;
    font-weight: 600;
  }
</style>
