import { render, staticRenderFns } from "./TrustYouHotelType.vue?vue&type=template&id=5d2c07e4&scoped=true&"
import script from "./TrustYouHotelType.vue?vue&type=script&lang=js&"
export * from "./TrustYouHotelType.vue?vue&type=script&lang=js&"
import style0 from "./TrustYouHotelType.vue?vue&type=style&index=0&id=5d2c07e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2c07e4",
  null
  
)

export default component.exports