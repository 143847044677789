<template>
  <component
    :is="customComponents"
    v-if="isImage"
    v-lazy="{ src: srcSource, error: errorImage || srcImg, loading: isLoading ? srcImg : undefined }"
    :data-alt="altImg">
    <slot />
  </component>
  <component
    :is="customComponents"
    v-else
    v-lazy:backgroundImage="{ src: srcSource, error: errorImage || srcImg, loading: isLoading ? srcImg : undefined }"
    :data-alt="altImg">
    <slot />
  </component>
</template>

<script>
const IMAGE_ORDER_SORT = {
  ASCENDING: { xs: 0, s: 1, m: 2, l: 3, xl: 4 },
  DESCENDING: { xs: 4, s: 3, m: 2, l: 1, xl: 0 }
}
export default {
  name: 'HotelImage',
  props: {
    image: {
      type: Object,
      default () {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDescending: {
      type: Boolean,
      default: false
    },
    customComponents: {
      type: [String, Object],
      default: 'div'
    },
    errorImage: {
      type: [String, Object],
      default: null
    },
    isImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sortedImages () {
      const orderSizes = this.isDescending ? IMAGE_ORDER_SORT.DESCENDING : IMAGE_ORDER_SORT.ASCENDING
      const keys = Object.keys(this.image)
      const sortedSize = this.sortOrder(keys, orderSizes)
      return sortedSize.map(size => this.image[size])
    },
    srcSource () {
      try {
        return this.sortedImages[0].url
      } catch {
        return ''
      }
    },
    altImg () {
      try {
        return this.sortedImages[this.sortedImages.length - 1].caption
      } catch {
        return ''
      }
    },
    srcImg () {
      try {
        return this.sortedImages[this.sortedImages.length - 1].url
      } catch {
        return ''
      }
    }
  },
  methods: {
    sortOrder (array, orderArray) {
      return array.sort((a, b) => {
        return orderArray[a.toLowerCase()] - orderArray[b.toLowerCase()]
      })
    }
  }
}
</script>
