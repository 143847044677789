<template>
  <div :class="['badge-outline', cssColor, cssTextShadow, size, cssBorderRadius, cssNoMargin]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BadgeOutline',
  props: {
    color: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    textShadow: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    borderRadius: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssColor () {
      return `b--${this.color}`
    },
    cssTextShadow () {
      return this.textShadow ? 'b--shadow-text' : ''
    },
    cssBorderRadius () {
      return this.borderRadius ? 'b--border-radius' : ''
    },
    cssNoMargin () {
      return this.noMargin ? '' : 'b--margin'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .badge-outline {
    background-color: #eff9ed;
    display: inline-block;
    padding: $bleed $bleed * 2;
    color: $dark;
    line-height: 1.2;

    &.b--margin {
      margin: $bleed $bleed * 2;
    }

    &.small {
      margin: $bleed/2 $bleed;
      padding: $bleed/2 $bleed;

      &.b--border-radius {
        border-radius: $bleed/2;
      }
    }

    &.b--red {
      background-color: $danger-color;
      color: $white;
    }

    &.b--green {
      background-color: $pos-color;
      color: $white;
    }

    &.b--blue {
      background-color: $primary;
      color: $white;
    }

    &.b--yellow {
      background-color: $yellow;
      color: $primary;
    }

    &.b--shadow-text {
      text-shadow: 0 1px 2px rgba(10, 10, 10, .8);
    }
  }
</style>
