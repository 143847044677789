<template>
  <span v-if="pct">
    <span class="has-text-weight-bold pos">{{ pct | pct({ factionDigits: 0 }) }}</span> {{ $t('components.TrustYouRecommendation.pct') }}
  </span>
  <!-- <span class="kkk">({{ recon.total | number }}</span> -->
  <!-- <span>
    Recommended by <span class="has-text-weight-semibold pos">{{ pct | pct({ factionDigits: 0 }) }}</span> of guests <span class="kkk">({{ recon.total | number }})</span>
  </span> -->
</template>

<script>
export default {
  name: 'TrustYouRecommendation',
  props: {
    reviewsDistribution: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    pct () {
      return this.recon.recommended / this.recon.total
    },
    recon () {
      return this.reviewsDistribution.reduce((pV, cV) => {
        pV.total = pV.total + cV.reviewsCount
        if (cV.stars > 2) {
          pV.recommended = pV.recommended + cV.reviewsCount
        }
        return pV
      }, {
        total: 0,
        recommended: 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .kkk {
    opacity: 0.5;
  }

  .pos {

  }

  .ranked {
    display: inline-block;
    background-color: #e86707;
    color: #fff;
    padding: $bleed $bleed * 3;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.2;
    text-shadow: 0 1px 2px rgba(10, 10, 10, .8);
    border-radius: $bleed / 2;

    .lab {
      display: block;
    }

    .val {
      display: block;
    }
  }
</style>
